<template>
	<div class="burger-score">
		<img class="burger-score__icon" :class="getRatingClass(1)" :src="getRatingImageUrl(1)" />
		<img class="burger-score__icon" :class="getRatingClass(2)" :src="getRatingImageUrl(2)" />
		<img class="burger-score__icon" :class="getRatingClass(3)" :src="getRatingImageUrl(3)" />
		<img class="burger-score__icon" :class="getRatingClass(4)" :src="getRatingImageUrl(4)" />
		<img class="burger-score__icon" :class="getRatingClass(5)" :src="getRatingImageUrl(5)" />
	</div>
</template>

<script>
export default {
	props: {
		score: { type: [Number, String], default: null },
		filled: { type: Boolean, default: false }
	},
	methods: {
		getRatingImageUrl (starIndex) {
			if (starIndex - Number(this.score) <= 0) {
				return require("@/assets/Images/Icons/fullBurger" + (this.filled ? "Filled" : "") + ".png")
			} else if (starIndex - Number(this.score) === 0.5) {
				return require("@/assets/Images/Icons/halfBurger" + (this.filled ? "Filled" : "") + ".png")
			} else {
				return require("@/assets/Images/Icons/emptyBurger" + (this.filled ? "Filled" : "") + ".png")
			}
		},
		getRatingClass (starIndex) {
			if (starIndex - Number(this.score) <= 0) {
				return "burger-score__icon--full"
			} else if (starIndex - Number(this.score) === 0.5) {
				return "burger-score__icon--half"
			} else {
				return "burger-score__icon--empty"
			}
		}
	}
}
</script>

<style lang="scss">
	.burger-score {
		color: #b78352;
		font-family: "Trebuchet MS", Helvetica, sans-serif;
		padding-left: 15px;
		justify-content: flex-end;
		opacity: 0.9;
		white-space: nowrap;
		margin-top: 4px;

		@media only screen and (max-width: 767px) {
			margin-top: 2px;
			padding-left: 0;
		}

		.burger-score__icon {
			height: 25px;
			margin-left: 2px;

			@media only screen and (max-width: 767px) {
				height: 21px;
			}

			&--empty {
				opacity: 0.5;
			}
		}
	}
</style>
