<template>
	<div class="joint-details">
		<div class="joint-details__bread-crumbs-container">
			<router-link
				class="joint-details__breadcrumb-link"
				:to="{ name: 'Burgers' }"
			>
				<Icon
					class="burger-details__link-icon burger-details__link-icon--ranking"
					:icon="['fas', 'list-ol']"
				/>
				<span class="joint-details__breadcrumb-link--ranking">Ranking</span>
			</router-link>
			<Icon
				:icon="['fas', 'angle-right']"
				class="joint-details__breadcrumb-arrow"
			/>
			<span
				class="joint-details__breadcrumb-text"
			>
				<Icon
					class="burger-details__link-icon burger-details__link-icon--joint"
					:icon="['fas', 'store']"
				/>
				<span class="joint-details__breadcrumb-link--joint">{{ joint.title }}</span>
			</span>
		</div>
		<div class="joint-details__container">
			<div class="joint-details__header">
				<h3 class="joint-details__title">{{ joint.title }}</h3>
				<div class="joint-details__links-container">
					<a
						v-for="link in joint.links.filter(link => !!link.url)"
						:key="link.url"
						:href="link.url"
						target="_blank"
						class="joint-details__link"
					>
						<Icon
							class="joint-details__link-icon"
							:icon="getLinkIcon(link.icon)"
						/>
					</a>
				</div>
			</div>
			<div
				v-if="joint.tags.length > 0"
				class="joint-details__tags"
			>
				<div
					v-for="tag in joint.tags"
					:key="tag"
					class="joint-details__tag"
					:class="'joint-details__tag--' + tag"
				>
					<Icon
						class="joint-details__tag-icon"
						:icon="getTagIcon(tag)"
					/>
					{{ getTagText(tag) }}
				</div>
			</div>
			<div class="joint-details__burgers-section">
				<div class="joint-details__burgers-title">
					Burgers
				</div>
				<div class="joint-details__burgers-container">
					<router-link
						v-for="(burger, key) in joint.burgers"
						:key="key"
						class="joint-details__burger"
						:class="{
							'joint-details__burger--discontinued': burger.tags.includes('discontinued'),
							'joint-details__burger--special-edition': burger.tags.includes('special-edition')
						}"
						:to="{
							name: 'BurgerDetails',
							params: {
								joint: joint.slug,
								burger: burger.slug
							}
						}"
					>
						<img
							class="joint-details__burger-image"
							:src="getBurgerImageUrl(burger)"
							:alt="burger.title"
						>
						<div class="joint-details__burger-meat-container">
							<IconTag
								:icon="['fac', burger.meat_types[0]]"
								color="black"
							/>
						</div>
						<div class="joint-details__burger-tags-container">
							<IconTag
								v-if="burger.tags && burger.tags.includes('discontinued')"
								color="gray"
								title="Discontinued"
								:icon="['fas', 'heart-crack']"
							/>
							<IconTag
								v-if="burger.tags && burger.tags.includes('special-edition')"
								color="orange"
								title="Special Edition"
								:icon="['fas', 'stopwatch']"
							/>
						</div>
						<div class="joint-details-text-wrapper">
							<div class="joint-details__burger-title">{{ burger.title }}</div>
							<BurgerScore
								class="joint-details__burger-score"
								:score="burger.rating"
							/>
						</div>
					</router-link>
				</div>
			</div>
			<div v-if="joint.review" class="joint-details__review-container">
				<div class="joint-details__review-title">Comments</div>
				<div class="joint-details__review-content" v-html="joint.review"/>
			</div>
		</div>
	</div>
</template>

<script>
import BurgerScore from "@/components/BurgerScore.vue"
import Slugify from "@/mixins/Slugify.js"
import IconTag from "@/components/IconTag.vue"

export default {
	components: {
		BurgerScore,
		IconTag
	},
	mixins: [
		Slugify
	],
	data () {
		return {
			clickedBurgersBreadcrumb: false
		}
	},
	computed: {
		joint () {
			return this.$store.getters.JointsAndBurgersBySlug[this.$route.params.joint]
		}
	},
	methods: {
		getTagText (tag) {
			return {
				"special-edition": "Special Edition",
				closed: "Closed"
			}[tag]
		},
		getTagIcon (tag) {
			return ["fas", {
				"special-edition": "stopwatch",
				closed: "heart-crack"
			}[tag]]
		},
		getBurgerImageUrl (burger) {
			const imageIndex = burger.sections.findIndex(section => section.type === "image")
			if (imageIndex !== -1) {
				return burger.sections[imageIndex].url
			} else if (burger.dish_type === "sandwich") {
				return require("../../assets/Images/Placeholders/placeholder_sandwich-color.png")
			} else if (burger.dish_type === "burger") {
				return require("../../assets/Images/Placeholders/placeholder_burger-color.png")
			} else {
				return require("../../assets/Images/Placeholders/placeholder_burger-color.png")
			}
		},
		getLinkIcon (icon) {
			return {
				icon_WEB: ["fas", "earth-africa"],
				icon_FB: ["fac", "facebook"],
				icon_TA: ["fac", "tripadvisor"]
			}[icon]
		}
	}
}
</script>

<style lang="scss">
	.joint-details {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;
		overflow: auto;
		background: #FAF1E4;
		padding-bottom: 64px;

		.joint-details__bread-crumbs-container {
			width: 100%;
			height: 80px;
			display: flex;
			align-items: center;
			background: #fefefe;
			padding: 0 87px;
			box-sizing: border-box;
			margin-bottom: 54px;
			box-shadow: 0 0 13px #0000001f;

			@media only screen and (max-width: 1200px) {
				padding: 0 30px;
			}

			@media only screen and (max-width: 820px) {
				padding: 0 20px;
			}

			@media only screen and (max-width: 767px) {
				padding: 0 20px;
				margin-bottom: 40px;
			}

			.joint-details__breadcrumb-link,
			.joint-details__breadcrumb-text {
				color: #2c3e50;
				font-size: 20px;
				font-family: 'Nunito Sans', sans-serif;
				font-weight: 500;
				letter-spacing: 0.8px;
				padding: 14px 14px;
			}

			.joint-details__breadcrumb-link {
				&:hover {
					background: #5274971f;
					border-radius: 5px;
				}

				&--joint {
					display:none;
				}

				&--ranking,
				&--joint {
					@media only screen and (max-width: 767px) {
						display:none;
					}
				}
			}

			.joint-details__breadcrumb-text {
				cursor: default;
			}

			.joint-details__breadcrumb-arrow {
				color: #2c3e50;
				padding: 0 10px;
			}

			.burger-details__link-icon {
				color: #2c3e50;
				font-size: 23px;
				margin-right: 10px;

				@media only screen and (max-width: 767px) {
					margin-right: 0px;
				}

				&.fa-store {
					font-size: 21px;
				}

				&.fa-list-ol {
					margin-bottom: -2px;
					font-size: 22px;
				}
			}

			.burger-details__link-icon--joint {
				margin-top: 1px;
			}
		}

		.joint-details__container {
			background: #FAF1E4;
			cursor: default;
			z-index: 1;
			border-radius: 5px;
			width: 700px;
			display: block;
			flex-direction: column;
			align-items: center;

			@media only screen and (max-width: 767px) {
				margin: 0;
				border-radius: 0;
				display: flex;
				width: calc(100% - 20px);
				margin: 0 10px;
			}

			.joint-details__burgers-title,
			.joint-details__review-title {
				background: #ef9443;
				border-radius: 4px 4px 0 0;
				font-size: 14px;
				font-weight: bold;
				letter-spacing: 2px;
				line-height: 20px;
				padding: 8px 16px;
				text-transform: uppercase;
				color: white;
			}

			.joint-details__header {
				background: #FAF1E4;
				display: flex;
				justify-content: space-between;
				align-items: center;
				margin-bottom: 40px;
				padding: 0 10px;
				width: 100%;
				box-sizing: border-box;

				@media only screen and (max-width: 767px) {
					margin: 0 30px 30px 30px;
					width: unset;
					flex-direction: column;
					align-items: center;
					text-align: center;
				}

				.joint-details__title {
					color: #2c3e50;
					font-size: 38px;
					letter-spacing: 0.5px;
					margin: 0;
				}

				.joint-details__links-container {
					display: flex;
					justify-content: flex-end;

					@media only screen and (max-width: 767px) {
						margin-top: 20px;
					}

					.joint-details__link {
						margin-left: 10px;

						.joint-details__link-icon {
							width: 36px;
							height: 36px;
							color: #2c3e50;
						}
					}
				}
			}

			.joint-details__tags {
				width: 100%;
				box-sizing: border-box;
				margin: 0 0 16px;

				@media only screen and (max-width: 767px) {
					width: 100%;
					max-width: 567px;
				}

				@media only screen and (max-width: 596px) {
					max-width: 383px;
				}

				@media only screen and (max-width: 412px) {
					width: 100%;
				}

				.joint-details__tag {
					box-sizing: border-box;
					width: 100%;
					line-height: 20px;
					border-radius: 4px;
					font-size: 17px;
					font-style: italic;
					font-weight: bold;
					letter-spacing: 2px;
					padding: 16px;
					text-align: center;
					text-transform: uppercase;
					box-shadow: 0 0 13px #0000001f;

					&--special-edition {
							background: #c33037;
							color: white;

							.joint-details__tag-icon {
								margin-right: 3px;
							}
					}

					&--closed {
						background: repeating-linear-gradient(
							45deg,
							#98B8B3,
							#98B8B3 10px,
							#8EABA6 10px,
							#8EABA6 20px
						);
						color: white;
					}
				}
			}

			.joint-details__burgers-section {
				display: flex;
				flex-direction: column;
				background: #ffffff;
				margin: 0 0 16px;
				border-radius: 5px;
				// box-shadow: 0 0 13px #0000001f;
				width: 100%;

				@media only screen and (max-width: 767px) {
					margin: 0px 15px;
					width: 567px;
				}

				@media only screen and (max-width: 596px) {
					width: 383px;
				}

				@media only screen and (max-width: 412px) {
					width: 100%;
				}

				.joint-details__burgers-container {
					// display: grid;
					// grid-template-columns: 1fr 1fr 1fr;
					// grid-auto-rows: 1fr;
					display: flex;
					flex-flow: wrap;
					justify-content: center;
					gap: calc((700px - (200px * 3)) / 4);
					padding: 24px;
					justify-content: flex-start;
					box-sizing: border-box;
					border-width: 0 1px 1px;
					border-style: solid;
					border-color: #0000001c;
					border-radius: 0 0 4px 4px;

					@media only screen and (max-width: 767px) {
						gap: 15px;
						padding: 15px;
					}

					@media only screen and (max-width: 412px) {
						justify-content: center;
					}

					.joint-details__burger {
						background: #FAF1E4;
						max-width: 200px;
						width: 240px;
						border-radius: 5px;
						overflow: hidden;
						display: flex;
						flex-direction: column;
						position: relative;

						@media only screen and (max-width: 767px) {
							width: 169px;
						}

						@media only screen and (max-width: 412px) {
							width: 240px;
						}

						&--discontinued {
							opacity: 0.4;
							transition: opacity 0.2s;
							background: repeating-linear-gradient(
								45deg,
								#9fbcb8,
								#9fbcb8 10px,
								#bdd1cf 10px,
								#bdd1cf 20px
							);
							box-shadow: 0 0 13px #0000001f;

							&:hover {
								opacity: 1;
							}
						}

						&--special-edition {
							background: repeating-linear-gradient(
								45deg,
								#f1b1b5,
								#f1b1b5 10px,
								#fdbfc1 10px,
								#fdbfc1 20px
							);
							box-shadow: 0 0 13px #0000001f;
						}

						.joint-details__burger-image {
							background: #dcdcdc;
							height: 150px;
							object-fit: cover;
							width: 100%;
							margin-bottom: 6px;

							@media only screen and (max-width: 767px) {
								height: 126px;
							}

							@media only screen and (max-width: 412px) {
								height: 150px;
							}
						}

						.joint-details__burger-meat-container {
							position: absolute;
							left: 5px;
							top: 5px;
							font-size: 18px;
							color: white;

							.icon-tag-element {
								position: relative;
								background: transparent;

								&:before {
									background: rgba(black, 0.6);
									width: 100px;
									height: 100px;
									content: "";
									position: absolute;
									transform: rotate(315deg);
									left: -60px;
									top: -60px;
									z-index: 1;
								}

								svg {
									z-index: 10;
								}
							}
						}

						.joint-details__burger-tags-container {
							position: absolute;
							top: 136px;
							right: 6px;
							display: flex;
							gap: 6px;

							@media only screen and (max-width: 767px) {
								top: 109px;
							}

							@media only screen and (max-width: 412px) {
								top: 133px;
							}

							.icon-tag-element {
								box-shadow: 1px 1px 8px 0px rgba(grey, 0.4);
							}
						}

						.joint-details-text-wrapper {
							flex-grow: 1;
							display: flex;
							flex-direction: column;
							justify-content: space-between;

							.joint-details__burger-title {
								display: flex;
								align-items: center;
								justify-content: center;
								flex-grow: 1;
								padding: 12px;
								font-size: 16px;
								font-weight: 700;
								text-align: center;
								color: #10171e;

								@media only screen and (max-width: 767px) {
									font-size: 14px;
									padding:  8px 12px;
								}
							}

							.joint-details__burger-score {
								width: 100%;
								justify-content: center;
								display: flex;
								padding: 0 0 24px;

								@media only screen and (max-width: 767px) {
									padding: 0 0 13px;
								}
							}
						}
					}
				}
			}

			.joint-details__review-container {
				@media only screen and (max-width: 767px) {
					margin: 16px 0;
					max-width: 567px;
				}

				@media only screen and (max-width: 596px) {
					max-width: 383px;
				}

				@media only screen and (max-width: 412px) {
					width: 100%;
				}

				.joint-details__review-content {
					background: #ffffff;
					padding: 24px 26px;
					font-size: 14px;
					line-height: 20px;
					border-width: 0 1px 1px;
					border-style: solid;
					border-color: #0000001c;
					border-radius: 0 0 4px 4px;

					p {
						&:first-of-type {
							margin-top: 0;
						}

						&:last-of-type {
							margin-bottom: 0;
						}
					}

					ul {
						margin: 0 0 0 15px;
						padding-inline-start: 18px;
					}

					li {
						padding-bottom: 5px;
					}

					.emphasized {
						letter-spacing: 0.1px;
						font-style: italic;
						padding-right: 2px;
					}

					.reference,
					.callback {
						font-weight: 600;
						letter-spacing: 0.2px;
						color: #cc8342;

						&:hover {
							text-decoration: underline;
						}
					}
				}
			}
		}
	}
</style>
